<template>
  <div class="app-container">
    <eHeader ref="header" :query="query" :sites="sites" @refresh-handle="refreshHandle"  />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="siteName" label="社区名称"/>
      <el-table-column prop="noticeTitle" label="标题">
        <template slot-scope="scope">
          <div>{{getTitle(scope.row)}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="noticeType" label="社区消息类型">
        <template slot-scope="scope">
          <span v-if="scope.row.noticeType==1">通知</span>
          <span v-if="scope.row.noticeType==2">公告</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">草稿</span>
          <span v-if="scope.row.status==1">发布</span>
          <span v-if="scope.row.status==2">失效</span>
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.updateTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" align="center">
        <template slot-scope="scope">
          <edit
              v-if="checkPermission(['ADMIN','COMMUNITYNOTICE_ALL','COMMUNITYNOTICE_EDIT'])" :data="scope.row"
              :sup_this="sup_this"
              :sites="sites"
              @refresh-handle="refreshHandle"
          />
          <el-popover
            v-if="checkPermission(['ADMIN','COMMUNITYNOTICE_ALL','COMMUNITYNOTICE_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del } from '@/api/community/srCommunityNotice'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/community/communitynotice/header'
import edit from '@/components/community/communitynotice/edit'
import { getSitesData } from '@/api/cms/srSite'
export default {
  name:'communitynotice',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      sites:[],
    }
  },
  mounted() {
    this.getSites();
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    getTitle(row){
      return row.noticeTitle || row.noticeTitleEn;
    },
    beforeInit() {
      this.url = 'community/crm/srCommunityNotice'
      const sort = 'id,desc'
      const query = this.query;
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        noticeTitle: query.noticeTitle,
        status: query.status,
      }
      if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
      }
      return true;
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    },
    getSites(){
      const params = {page:0,size:200}
      getSitesData(params).then(res => {
        this.sites = res.content;
      })
    },
    refreshHandle(){
      this.init();
    }
  }
}
</script>

<style scoped>

</style>
