<template>
  <div class="head-container">
    <!-- 搜索 -->
    <search-community ref="searchcommunity"></search-community>
    <el-input v-model="query.noticeTitle" clearable placeholder="输入标题" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select v-model="query.status" clearable placeholder="选择状态" class="filter-item" style="width: 130px">
      <el-option v-for="item in queryTypeOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','COMMUNITYNOTICE_ALL','COMMUNITYNOTICE_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true">新增</el-button>
      <eForm ref="form" @refresh-handle="$emit('refresh-handle')" :is-add="true" :sites="sites"/>
    </div>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
export default {
  components: { eForm,searchCommunity },
  props: {
    query: {
      type: Object,
      required: true
    },
    sites:{
      type: Array,
      required: true
    },
  },
  data() {
    return {
      queryTypeOptions: [
        { key: '0', display_name: '草稿' },
        { key: '1', display_name: '有效' },
        { key: '2', display_name: '无效' }
      ],
      langOptions:[
        {
          label: '中文',
          value: 'zh_CN',
        },
        {
          label: '英文',
          value: 'en_US',
        }
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
