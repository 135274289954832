<template>
  <el-dialog
      :append-to-body="true"
      :visible.sync="dialog"
      v-if="dialog"
      :title="isAdd ? '新增' : '编辑'"
      width="800px"
      class="dialog-el"
  >
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-form-item label="社区名称" prop="siteId">
        <el-select v-model="form.siteId" filterable placeholder="请选择" style="width: 300px;">
          <el-option
            v-for="item in sites"
            :key="item.id"
            :label="item.siteName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
<!--      与选择的社区联动,选项里是当前社区名称的语言列表 todo-->

      <el-form-item label="消息类型" prop="noticeType">
        <el-radio disabled  v-model="form.noticeType" label=1>通知</el-radio>
        <el-radio disabled  v-model="form.noticeType" label=2>公告</el-radio>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="form.status" label=0>草稿</el-radio>
        <el-radio v-model="form.status" label=1>发布</el-radio>
        <el-radio v-model="form.status" label=2>失效</el-radio>
      </el-form-item>
      <el-form-item>
        <div class="lang-tabs">
          <span
              class="lang-item"
            v-for="(item,index) in langList"
            :key="index"
            :class="{on: currentLanguage == item.value}"
              @click="langItemHandle(item)"
          >{{item.label}}</span>
        </div>
      </el-form-item>
      <div
        v-show="currentLanguage == 'zh_CN'"
      >
        <el-form-item label="标题"  prop="noticeTitle">
          <el-input v-model="form.noticeTitle" style="width: 370px;"/>
        </el-form-item>
        <el-form-item prop="noticeContent" label="内容">
          <quillEditor
              :uploadHelperShow="false"
              @catchData="catchData"
              :defaultValues="form.noticeContent"
          ></quillEditor>
        </el-form-item>
      </div>
      <div
          v-show="currentLanguage == 'en_US'"
      >
        <el-form-item label="标题"  prop="noticeTitleEn">
          <el-input v-model="form.noticeTitleEn" style="width: 370px;"/>
        </el-form-item>
        <el-form-item prop="noticeContentEn" label="内容">
          <quillEditor
              :uploadHelperShow="false"
              @catchData="catchDataEn"
              :defaultValues="form.noticeContentEn"
          ></quillEditor>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/community/srCommunityNotice'
import quillEditor from '@/components/tools/quillEditor'
export default {
  components: { quillEditor },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sites:{
      type: Array,
      required: true
    },
  },
  data() {
    return {
      loading: false, dialog: false,
      form: {
        id: '',
        siteId: '',
        noticeType: '2',
        noticeTitle: '',
        noticeContent: '',
        status: '0'
      },
      rules: {
        noticeTitle: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 1, max: 400, message: "长度在 1 到 400 个字符(200个汉字)", trigger: "blur" }
        ],
        noticeContent: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        noticeTitleEn: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 1, max: 400, message: "长度在 1 到 400 个字符(200个汉字)", trigger: "blur" }
        ],
        noticeContentEn: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
      },
      currentLanguage: '',
    }
  },
  computed: {
    //选择语言版本
    langList() {
      if(!this.form.siteId){
        return [];
      }else {
        let finalArr = [];
        let twoLang = [
          {
            label: '中文',
            value: 'zh_CN',
          },
          {
            label: '英文',
            value: 'en_US',
          }
        ];
        let zhLang = [
          {
            label: '中文',
            value: 'zh_CN',
          }
        ];
        let enLang = [
          {
            label: '英文',
            value: 'en_US',
          }
        ]

        for (let i = 0; i < this.sites.length; i++) {
          if(this.sites[i].id == this.form.siteId){
            if(this.sites[i].availableLang == '1,2'){
              finalArr = twoLang;
              this.currentLanguage = 'zh_CN';
            }else if(this.sites[i].availableLang == '1'){
              finalArr = zhLang;
              this.currentLanguage = 'zh_CN';
            }else if(this.sites[i].availableLang == '2'){
              finalArr = enLang;
              this.currentLanguage = 'en_US';
            }
            break;
          }
        }
        return finalArr;
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    langItemHandle(item){
      this.currentLanguage = item.value;
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    handleParams(){
      let params = {
        ...this.form,
      }
      return params;
    },
    doAdd() {
      add(this.handleParams()).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.refresh();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.handleParams()).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.refresh();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    refresh(){
      this.$emit('refresh-handle');
    },
    resetForm() {
      this.$refs['form'].resetFields();
      this.form = {
        id: '',
        siteId: '',
        noticeType: '2',
        noticeTitle: '',
        noticeContent: '',
        noticeTitleEn: '',
        noticeContentEn: '',
        status: '0'
      };
      this.currentLanguage = '';
      this.dialog = false
    },
    catchData(value){
      //在这里接受子组件传过来的参数，赋值给data里的参数
      this.form.noticeContent=value;
    },
    catchDataEn(value){
      //在这里接受子组件传过来的参数，赋值给data里的参数
      this.form.noticeContentEn = value;
    },
  }
}
</script>

<style lang="less" scoped>
.dialog-footer{
  margin-top: 40px;
}
.dialog-el {
  .lang-item {
    cursor: pointer;
    margin-right: 20px;
    padding-bottom: 10px;
    &.on {
      color: #409EFF;
      border-bottom: 2px solid #409EFF;
    }
  }
}
</style>
