<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm
        ref="form"
        :sup_this="sup_this"
        :is-add="false"
        :sites="sites"
        @refresh-handle="refreshHandle"
    />
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    sites:{
      type: Array,
      required: true
    },
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        siteId: this.data.siteId,
        noticeType: this.data.noticeType.toString(),
        noticeTitle: this.data.noticeTitle,
        noticeContent: this.data.noticeContent,
        noticeTitleEn: this.data.noticeTitleEn,
        noticeContentEn: this.data.noticeContentEn,
        status: this.data.status.toString()
      }
      _this.dialog = true
    },
    refreshHandle(){
      this.$emit('refresh-handle');
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
